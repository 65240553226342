<template>
  <div class="article safe-area">
   <div class="margin-top-20">
     <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/mall/home' }"><i class="el-icon-house"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:  '/mall/articleList' }"><i class="el-icon-tickets"></i> 通知公告</el-breadcrumb-item>
      </el-breadcrumb>
   </div>
    <div class="column margin-top-20">
      <div ref="main">
        <div class="flex-row aligin-item-center justify-between padding-tb-10 border-bottom link" v-for="(item,index) in notices.list" :key="index"
        @click="$root.jump({path:'/mall/articleDetail',query:{id:item.notice_id}})">
          <div class="">{{item.notice_title}}</div>
          <div class="color-sub">{{item.pub_time}}</div>
        </div>
        <div v-if="notices.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="notices.noMore" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  name: 'Home',
  data() {
    return { 
      notices:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[],
        bodyScrollHeight:0
      },
    }
  },
  computed:{

  },
  methods:{
    //获取推荐商品列表
    getNoticeList(){
      this.notices.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.notices.page,
        page_size:this.notices.pageSize
      }
      this.$instance.get('/main/get_notice_list',{params})
      .then(res=>{
        this.notices.loading=false
        if(res.data.code==0){
          this.notices.list=this.notices.list.concat(res.data.data)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          if(res.data.data.length<this.notices.pageSize){
            this.notices.noMore=true
          }else{this.notices.page+=1}
          if(this.notices.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.notices.loading=false
      }); 
    },
    //加载更多商品
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.notices.loading&&!this.notices.noMore,
        offset:0,
        callBack:this.getNoticeList,
      })
    },
  },
  created(){
    this.getNoticeList()
  },
  mounted(){
    
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
<style>

</style>
